import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, Modal, Select, Table, message, Popconfirm, DatePicker, Skeleton } from 'antd';
import { EditOutlined, DeleteOutlined} from '@ant-design/icons';
import { api } from '../api/api';
import dayjs from 'dayjs';
import { resources } from '../api/resources';
import { formatNumber } from '../utils/function';

const { RangePicker } = DatePicker;


const PromoCategory = () => {
  const [promoCategory, setPromoCategory] = useState([]);
  const [promo, setPromo] = useState([]);
  const [category, setCategory] = useState({})
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [workingId, setWorkingId] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshState, setRefreshState] = useState();

  const [editForm] = Form.useForm();

  const handleEditCancel = async () => {
    await editForm.resetFields()
    setEditModalOpen(false);
  }

  const showEditModal = async (num) => {
    // setWorkingId(null)
    try {
        const { data } = await api.get(resources.promoCategoryUrl +`/${num}`)
        setCategory(data)

        if(data) {
            setEditModalOpen(true);
            setWorkingId(num);
        }
    } catch (error) {
        message.error("Can't edit category at this moment, try again!")
    }
  }

  const handleSpaceEdit = async () => {
    try {
        setEditLoading(true)
        const values = await editForm.validateFields()
        const payload = {
            ... values,
            startDate: values.dates[0],
            endDate: values.dates[1]
        }
        if(workingId) {
            const { data } = await api.patch(resources.promoCategoryUrl + `/${workingId}`, payload)
            if(data) setEditModalOpen(false)
            setRefreshState(data.num)
            message.success("Update successful")
        }
    } catch (error) {
        message.error("There was an error performing operation!")
    }
    setEditLoading(false)

  }

  const fetchData = async() => {
      setLoading(true)
      try {
          const { data } = await api.get(resources.promoCategoryUrl)
          const { data: promo} = await api.get(resources.promoUrl)
          setPromoCategory(data)
          setPromo(promo)

      } catch (error) {
        message.error('Network error!')
      }
      setLoading(false)
  }

  const handleRefresh = () => fetchData()

  useEffect(()=> {
    fetchData()
  }, [refreshState])


const columns = [
    {
      title: '#',
      dataIndex: '_id',
      key: '_id',
      render: (val,record, i) => i + 1, 
    },
    {
        title: "Name",
        dataIndex: 'name',
        key: '_id'
    },
    {
        title: "Type",
        dataIndex: 'type',
        key: '_id'
    },
    {
      title: 'From',
      dataIndex: 'startDate',
      key: '_id',
      render: (date) => dayjs(date).format('MMM YYYY')
    },
    {
      title: 'To',
      dataIndex: 'endDate',
      key: '_id',
      render: (date) => dayjs(date).format('MMM YYYY')
    },
    {
      title: 'PromoCode',
      dataIndex: 'promoCode',
      key: '_id',
      render: (code) => code || "------" 
    },
    {
      title: 'Amount',
      dataIndex: 'price',
      key: '_id',
      render: (amount) => `₦ ${formatNumber(amount)}`
    },
    {
      title: '',
      dataIndex: 'num',
      key: 'num',
      render: (num) => {
        return <span className='flex gap-4 items-center cursor-pointer'>
            <EditOutlined className='text-lg' onClick={() => showEditModal(num)} /> 
            </span>
      }
    },
  ];
  return (
        <>
            <div className='bg-white p-5'>
                <div className='w-full p-3 flex justify-end gap-4'>
                <Button onClick={handleRefresh} className='border-[#4096FF]'>Refresh</Button>
                </div>
                {
                    loading ? (
                        <Skeleton active className='mt-5' />
                      ) : (
                        <Table 
                            bordered 
                            columns={columns} 
                            pagination={{
                                position: ['topRight'],
                            }} 
                            dataSource={promoCategory} 
                        />
                      )
                }
                
            </div>
            
            <Modal 
                title='Edit Promo Category' 
                open={isEditModalOpen} 
                onOk={handleSpaceEdit} 
                onCancel={handleEditCancel}
                okType='default'
                okText='Save'
                confirmLoading={editLoading}
            >
                <Form layout='horizontal' 
                    form={editForm}
                    initialValues={{ ...category }}
                >
                    <Form.Item 
                        label="Enter Promo Name"
                        name="name"
                        rules={[
                            {
                            required: true,
                            message: 'Please enter a name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        label="Promo Type"
                        name="type"
                        rules={[
                            {
                            required: true,
                            message: 'Please select a type',
                            },
                        ]}
                    >
                        <Select>
                            <Select.Option value="weekly">Weekly</Select.Option>
                            <Select.Option value="daily">Daily</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item 
                        label="Select Promo Period"
                        name="dates"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a date',
                            },
                        ]}
                    >
                        <RangePicker format={"MMMM YYYY"} picker="month" />
                    </Form.Item>
                    <Form.Item 
                        label="Price"
                        name="price"
                        rules={[
                            {
                            required: true,
                            message: 'Please enter Price',
                            },
                        ]}
                    >
                        <InputNumber addonBefore="₦" />
                    </Form.Item>
                    <Form.Item 
                        label="Promo Code"
                        name="promoCode"
                    >
                        {/* <Input /> */}
                        <Select>
                            {
                                promo.map((p) => (
                                    <Select.Option value={p?.code}>{p?.code}</Select.Option>
                                ))
                            }
                            {/* <Select.Option value="weekly">Weekly</Select.Option> */}
                            <Select.Option value={null}>None</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default PromoCategory;